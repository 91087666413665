<template>
    <div>
        <div class="container">
            <!--<video playsinline autoplay loop muted class="video-parallax">
                <source src="../assets/Durance2020V3.webm" type="video/webm">
            </video>-->
            <img src="../assets/Title_New.jpeg" class="img-parallax"/>
        </div>
        <div class="headercontainer">
            <div>
                <h1>Gallerie</h1>
            </div>
        </div>
        <div class="top-div-decoration">
        </div>
        <div class="container">
          <div class="breadcrumb">
            <a v-for="p in paths" :key="p" href="#" v-on:click="gotofolder(p)">{{p}}</a>
          </div>
        </div>
        <div class="container">
          <div class="img-container">
            <button v-for="folder in folders" :key="folder" v-on:click="openfolder(folder.path_display)">
              <img src="../assets/folder.png" />
              <br/>
              {{folder.name}}
            </button>
            <img v-for="image in images" :key="image" v-bind:src="image" class="img-fluid"
              v-on:click="openimage(image)"/>
          </div>
        </div>
        <div class="container">
          <div class="controls">
            <button :disabled='backwardDisabled' id="btnBackward" v-on:click="lastPage()" class="defaultButton">
              Zurück
            </button>
            <label>
              Seite {{currentPage}} von {{maxPage}}
            </label>
            <button :disabled='forwardDisabled' id="btnForward" v-on:click="nextPage()" class="defaultButton">
              Weiter
            </button>
          </div>
        </div>
        <div class="bottom-div-decoration">
        </div>
        <div id="myModal" class="modal">
          <div class="modal-content">
            <span class="close" v-on:click="closeimage">&times;</span>
            <img v-bind:src="currentimage" class="img-fluid-solo"/>
          </div>
        </div>
        <div id="filler" class="remaining">
        </div>
      </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'Home',
  props: {
    msg: String
  },
  mounted:function(){
    this.onload();
  },
  data: function() {
    return {
      message: 'Some Message',
      headers:{
        'Authorization':'Bearer ' + 'N6nrSq1fh_sAAAAAAAFJZfiGOTUKN6kqQ9nr3FbyB8CUo0zcbpLzm8Lbcek5y8Z5'
      },
      paths:[],
      path:"",
      currentpath:"",
      currentimage:"",
      limit:25,
      folders:[],
      images:[],
      currentCursor:0,
      cursors:[],
      allImages:[],
      page:0,
      itemsPerPage:20,
      currentPage:1,
      maxPage:1,
      forwardDisabled:false,
      backwardDisabled:true
    };
  },
  methods: {
    onload:function(){
      if(this.paths.length == 0){
        this.paths.push("Start");
      }
      axios.post("https://api.dropboxapi.com/2/files/list_folder",
          {
              path:this.path
          },
          {
            headers:this.headers
          }
      ).then(result => {
        this.allImages = result.data.entries;
        this.maxPage = Math.ceil(this.allImages.length / this.itemsPerPage);

        if(this.maxPage == 1){
          this.forwardDisabled = true;
        }
        else{
          this.forwardDisabled = false;
        }

        this.allImages.slice(this.page, this.itemsPerPage).forEach(element => {
          if(element[".tag"] === "folder"){
            this.folders.push(element);
          }
          else{
            axios.post("https://api.dropboxapi.com/2/files/get_temporary_link",
            {
              path:element.path_display
            },
            {
              headers:this.headers
            })
            .then(response=>{
              this.images.push(response.data.link)
            })
          }
        });
      }, error => {
        console.error(error);
      });
    },
    openfolder:function(currentpath){
      this.path += currentpath;
      if(currentpath !== ""){
        this.paths.push(currentpath);
      }
      this.currentpath = currentpath;

      this.folders = [];
      this.images = [];

      this.onload();
    },
    gotofolder:function(path){
      if(this.currentpath === path){
        return;
      }
      if(this.paths[this.paths.length - 1] === path){
        if(path === "Start"){
          this.path = "";
          this.openfolder("");
          return;
        }
        this.openfolder(path);
        return;
      }
      this.paths.pop();
      this.path.replace(path, "");
      this.gotofolder(this.paths[this.paths.length - 1]);
    },
    openimage:function(image){
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.currentimage = image;
    },
    closeimage:function(){
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
    nextPage:function(){

      console.log("next page");
      this.folders = [];
      this.images = [];
      this.page++;
      this.currentPage++;

      if(this.currentPage == this.maxPage){
        this.forwardDisabled = true;
      }
      else{
        this.forwardDisabled = false;
      }
      this.backwardDisabled = false;

      this.loadPage();
    },
    lastPage:function(){
      console.log("last page");
      this.folders = [];
      this.images = [];
      this.page--;
      this.currentPage--;

      if(this.currentPage == 1){
        this.backwardDisabled = true;
      }
      else{
        this.backwardDisabled = false;
      }
      this.forwardDisabled = false;

      this.loadPage();
    },
    loadPage:function(){
      var startCounter = this.page * this.itemsPerPage;
      var endCounter = startCounter + this.itemsPerPage;
      console.log(startCounter);
      console.log(endCounter);
        this.allImages.slice(startCounter, endCounter).forEach(element => {
          if(element[".tag"] === "folder"){
            this.folders.push(element);
          }
          else{
            axios.post("https://api.dropboxapi.com/2/files/get_temporary_link",
            {
              path:element.path_display
            },
            {
              headers:this.headers
            })
            .then(response=>{
              this.images.push(response.data.link)
            })
          }
        });
    }
  }
}
</script>

<style scoped>

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.remaining{
  background-color: rgba(80, 80, 80, 0.6);
}

@supports (-webkit-text-stroke: 1px black) {
  .headercontainer h1 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h3 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h6 {
    -webkit-text-stroke: 0.5px #2c3e50;
  }
}

.headercontainer{
  background-color: transparent;
  display:flex;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

@media screen and (max-width: 600px){
  .headercontainer{
    background-color: transparent;
    display:flex;
    /*background-color: rgba(80, 80, 80, 0.6);*/
    padding-top:40px;
  }
}

.headercontainer h1{
  color: #f2f2f2;
}

video{
  width: 100%;
  z-index: -100;
}

.video-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
}

.img-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
    z-index: -100;
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.headercontainer > div{
  background-color:transparent;
  flex:1;
  color: white;
  font-size: 25px;
}

.container{
  background-color: #fff;
  display:flex;
}

.container:first-child{
  height: 10vh;
  background-color: rgba(80, 80, 80, 0.0);
}

.container > div{
  flex:1;
  background-color:transparent;
  color: #2c3e50;
  font-size: 16px;
  margin: 25px 8vw 10px 8vw;
}

.clear{
  background-color: transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.container div p{
  font-weight: bold;
}

.top-div-decoration{
  border-bottom: 75px solid white;
  border-right: 100vw solid transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.top-div-decoration h1{
  position: absolute;
  left: 100px;
  font-size: 80px;
}

.bottom-div-decoration {
  border-top: 75px solid white;
  border-left: 100vw solid transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.bottom-div-decoration-text{
  position: absolute;
  right: 120px;
  font-size: 80px;
  margin-top: 0px;
}

.img-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.img-fluid{
  max-width: 15vw;
  height: auto;
  align-self: center;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 2px 5px 10px #2d2d2d;
}

@media screen and (max-width: 800px){
    .img-fluid{
      max-width: 23vw;
    }
}

@media screen and (max-width: 600px){
    .img-fluid{
      max-width: 35vw;
    }
}

.img-fluid-solo{
  width: 100%;
  height: auto;
}

.breadcrumb{
  background-color: lightgray !important;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.defaultButton{
  background-color: #2c3e50;
  color: white;
  border-radius: 5px;
  padding: 15px 32px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px;
}

@media screen and (max-width: 600px){
    .defaultButton{
      background-color: #2c3e50;
      color: white;
      border-radius: 5px;
      padding: 10px 20px;
      border: none;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 5px;
  }
}

.defaultButton:disabled{
  background-color: #474a4c;
}
</style>
